import React, {useEffect, useState} from "react";
import {getToken, handleAuthenticationError} from "../../userAuth";
import {Card, Row} from "react-bootstrap";
import {BellCurve} from "../viewresults/BellCurve";
import {getGenomicResultsApi} from "../../api/apiDNA";
import {toTitleCase} from "../../utils";
import Tooltip from "@mui/material/Tooltip";
import {HelpOutline} from "@mui/icons-material";


export default function ResultsDNA() {

    const [results, setResults] = useState([])

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getGenomicResultsApi({token: getToken()});
                setResults(response.data.results);
            } catch (error) {
                handleAuthenticationError(error);
                alert(`Failed to fetch results (${error.response.status}).\nServer might be under maintenance.\nTry again later.`);
            }
        }

        void fetchData();
    }, [])

    return (
        <>
            <Row className="align-items-center justify-content-center center">
                {
                    results?.map((result, index) => (
                        <Card className="m-1 p-0" key={index} style={{maxWidth: '19rem'}}>
                            <Card.Body>
                                <BellCurve
                                    scores={{mean: 0, std: 1, score: result['Z Score'] ?? '-'}}
                                    title={result['TRAIT'] ?? '-'}
                                    height={200}
                                />
                                <Card.Footer style={{position: 'relative'}}>
                                    {
                                        Object.entries(result)
                                            .filter(([key]) => key !== 'INFORMATION' && key !== 'TRAIT')
                                            .map(([key, value]) =>
                                                <Card.Subtitle key={key} className="mb-1">
                                                    {toTitleCase(key)}: {value ?? '-'}
                                                </Card.Subtitle>
                                            )
                                    }
                                    <Tooltip title={result['INFORMATION'] ?? "result['INFORMATION'] goes here"}
                                             placement="top">
                                        <HelpOutline fontSize="small"
                                                     style={{position: 'absolute', top: '5px', right: '5px'}}/>
                                    </Tooltip>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    ))
                }
            </Row>
        </>
    );
}