import {axios, backendUri} from "./config";

export const signupApi = (props) => axios.post(`${backendUri}/signup`, props);

export const loginApi = (props) => axios.post(`${backendUri}/login`, props);

export const loginAnonymouslyApi = (props) => axios.post(`${backendUri}/loginAnonymously`, props);

export const loginGoogleApi = (props) => axios.post(`${backendUri}/loginGoogle`, props);

export const changePasswordApi = (props) => axios.post(`${backendUri}/changePassword`, props);

export const deleteAccountApi = (props) => axios.post(`${backendUri}/deleteAccount`, props);

export const deleteResponsesApi = (props) => axios.post(`${backendUri}/deleteResponses`, props);

export const deleteDnaApi = (props) => axios.post(`${backendUri}/DNAdelete`, props);