import React, { useEffect, useState } from "react";
import { getToken, handleAuthenticationError } from "../../userAuth";
import { Card, ListGroup } from "react-bootstrap";
import { getJobStatus, boolPreviousJob } from "../../api/apiDNA";

const STATUS_ORDER = [
    "Queued",
    "Initial Genome Processes",
    "File Conversion",
    "Imputation",
    "Scoring",
    "Post Analysis Processes",
    "Complete",
    "Cancelled"
];

export default function JobStatus({ 
    hasUploaded,
    hasResults,
    updateHasUploaded,
    updateHasResults
}) {
    const [jobStatus, setJobStatus] = useState({});
    const [error, setError] = useState(null);
    const [isPreviousLogs, setPreviousLogs] = useState(false);

    useEffect(() => {
        async function checkPreviousJob() {
          if (!hasUploaded && !hasResults) {
            const PreviousLogs = await boolPreviousJob({ token: getToken() });
            setPreviousLogs(PreviousLogs.data.status);
          }
        }
        void checkPreviousJob();
    }, [hasUploaded, hasResults]);

    useEffect(() => {
        async function fetchJobStatus() {
            if (hasUploaded || hasResults || isPreviousLogs) {
                try {
                    const response = await getJobStatus({ token: getToken() });
                    setJobStatus(response.data);
                    setError(null);
    
                    // Check if the job was cancelled or completed
                    const statusEntries = Object.entries(response.data);
                    const lastStatus = statusEntries[statusEntries.length - 1];
                    
                    if (lastStatus[0] === 'Cancelled') {
                        updateHasUploaded(false);
                    }
                    if (lastStatus[0] === 'Complete') {
                        updateHasResults(true);
                    }
    
                } catch (error) {
                    handleAuthenticationError(error);
                    setError("Failed to fetch job status. Server might be under maintenance. Try again later.");
                }
            }
        }

        if (hasUploaded && !hasResults) {
            fetchJobStatus(); // Fetch immediately when uploaded
            const intervalId = setInterval(fetchJobStatus, 60000);
            return () => clearInterval(intervalId);
        } else if (isPreviousLogs) {
            fetchJobStatus(); // Fetch once for previous logs
        }
    }, [hasUploaded, hasResults, isPreviousLogs, updateHasResults, updateHasUploaded]);

    const getStatusColor = (status, isActive) => {
        if (status === 'Cancelled') {
            return 'text-danger';
        }
        if (status === 'Complete') {
            return 'text-success';
        }
        if (isActive) {
            return 'text-primary';
        }
        return 'text-muted';
    };

    const getStatusEmoji = (status, isActive, isComplete) => {
        if (status === 'Cancelled') {
            return '💀';
        }
        if (status === 'Complete') {
            return '✅';
        }
        if (isActive) {
            return '⌛';
        }
        return isComplete ? '✅' : '⭕';
    };

    const orderedStatuses = STATUS_ORDER.filter(status => jobStatus.hasOwnProperty(status));
    const lastActiveIndex = orderedStatuses.findIndex(status => !jobStatus[status].status);

    return (
        <Card className="mt-3">
            <Card.Header>Job Status</Card.Header>
            <Card.Body>
                {error ? (
                    <Card.Text className="text-danger">{error}</Card.Text>
                ) : (
                    <ListGroup variant="flush">
                        {orderedStatuses.map((status, index) => {
                            const details = jobStatus[status];
                            const isActive = index === lastActiveIndex - 1;
                            const isComplete = details.status;
                            return (
                                <ListGroup.Item
                                    key={status}
                                    className={getStatusColor(status, isActive)}
                                >
                                    <strong>{status}</strong>
                                    {details.entry_time && (
                                        <>: {new Date(details.entry_time).toLocaleString()}</>
                                    )}
                                    <p className="mb-0 mt-1">
                                        <small>
                                            {getStatusEmoji(status, isActive, isComplete)} {details.description}
                                        </small>
                                    </p>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                )}
            </Card.Body>
        </Card>
    );
}