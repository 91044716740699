import Container from "react-bootstrap/Container";
import {Button, Stack} from "@mui/material";
import React from "react";
import {deleteAccountApi, deleteResponsesApi, deleteDnaApi} from "../../api/apiAuth";
import {getAnonymousKey, getToken, logOutUser} from "../../userAuth";

export default function AccountManagement() {

    function deleteAccount(e) {
        e.preventDefault();
        if (window.confirm("Are you absolutely sure you want to delete your account?")) {
            try {
                void deleteAccountApi({
                    token: getToken(),
                    anon_key: getAnonymousKey(),
                })
                void deleteDnaApi({
                    token: getToken(),
                })
                logOutUser();
            } catch (error) {
                alert("Account deletion failed. Are you logged in?");
            }
        }
    }

    function deleteResponses(e) {
        e.preventDefault();
        if (window.confirm("Are you absolutely sure you want to delete your responses?")) {
            try {
                void deleteResponsesApi({
                    token: getToken(),
                })
                window.confirm("Responses deleted!");
            } catch (error) {
                alert("Account deletion failed. Are you logged in?");
            }
        }
    }

    function deleteDNA(e) {
        e.preventDefault();
        if (window.confirm("Are you absolutely sure you want to delete your DNA?")) {
            try {
                void deleteDnaApi({
                    token: getToken(),
                })
                window.confirm("DNA deleted!");
            } catch (error) {
                alert("DNA deletion failed. Are you logged in?");
            }
        }
    }

    return (
        <Container className="border p-4">
            <Stack gap={1}>
                {<Button href="/changePassword">Change Password 🔑</Button>}
                {<Button href="/" onClick={deleteAccount}>Delete Account 🗑️</Button>}
                {<Button href="/" onClick={deleteResponses}>Delete Responses 🗑️</Button>}
                {<Button href="/" onClick={deleteDNA}>Delete DNA 🗑️</Button>}
                <hr/>
                <Button href="/account">Back To Settings ⚙️</Button>
            </Stack>
        </Container>
    );

}